body,
* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}
#root {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
}
.page {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  /* border: 1px solid black;
  border-radius: 4px; */
  position: relative;
}
.welcome-section {
  border-bottom: 1px solid #ccc;
  /* text-align: center; */
  padding: 1rem;
  /* line-height: 1.5rem; */
  /* min-height: 10vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
}

.headerCheckBoxes {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 25px;
  grid-row-gap: 5px;
}

.headerCheckBoxes .inp {
  display: flex;
  gap: 5px;
}
.headerCheckBoxes .inp input,
.headerCheckBoxes .inp label {
  padding: unset;
  width: unset;
  margin-bottom: unset;
  cursor: no-drop;
}

.sections {
  display: flex;
  width: 100%;
  /* min-height: 50vh; */
  /* height: 100%; */
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}
.section-part {
  height: 100% !important;
  position: relative;
  flex-basis: 50%;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.section-content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  padding: .5rem 1rem;
}

.innen-section {
  /* height: 20%; */
  display: flex;
  flex-direction: column;
}
.innen-content {
  overflow: auto;
  height: 100%;
  /* flex-grow: 1; */
}

.debug-section, .logs-section {
  /* flex-grow: 1; */
  height: 40%;
}


.no-padding {
  padding: 0 !important;
}
.reset-section-padding {
  margin-right:-1rem;
  margin-left:-1rem;
  width: calc(100% + 2rem) !important;
  margin-bottom: 1rem;
}
.input-form
{
  flex-grow: 1;
}

select,
button,
input,
textarea {
  width: 100%;
  padding: 0.25rem;
  margin-bottom: 1rem;
}
select,
button,
input {
  cursor: pointer;
}

label {
  font-size: 14px;
  width: 100%;
  margin-bottom: 4px;
  display: block;
}

.command-section .c-wrap form .submitButton {
  /* width: calc(100% - 2rem); */
  /* margin: 1rem !important; */
  /* padding: 0.5rem; */
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  
}

.copyright {
  margin: 1rem 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -3rem;
  left: 0;
  width: 100%;
}

a {
  font-size: 14px;
  color: blue;
}

textarea {
  min-height: 75px;
  max-height: 150px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.fieldElements {
  display: none;
}

.results-section {
  width: 100%;
  flex-basis: 50%;
  overflow: hidden;
}
.output {
  height: 100%;
  overflow: hidden;
}

.output-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  padding: 0.45rem 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#results,
.quques-wrapper {
  height: 100%;
  overflow: hidden;
  padding: 1rem;
}
.quques-wrapper {
  font-weight: 700;
  color: #666;
}


#queues-list {
  padding: 0;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  color: black;
  margin: -1rem;
}
#queues-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: .5rem; */
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ccc;
}

#queues-list li .status {
  text-transform: uppercase;
  background: #ccc;
  color: black;
  font-weight: bold;
  font-size: 80%;
  padding: 4px 7px;
  border-radius: 4px;
}
#queues-list li .status.warning {
  color: #000;
  background-color: #f5ca99;
}
#queues-list li .status.success {
  color: #fff;
  background-color: #00ab8e;
}
#queues-list li .status.danger {
  color: #fff;
  background-color: #ed4c78;
}
#queues-list li .delete {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
  padding: 4px 7px;
  border-radius: 4px;
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  cursor: pointer;
}
#queues-list li .btns {
  display: flex;
  align-items: center;
  gap: 4px;
}

.alert-warning {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.15);
  position: relative;
  font-size: 90%;
  padding: 4px 1rem;
  border-radius: 0;
  border: 0.0625rem solid transparent;
  width: 100%;

}

@media screen and (max-width: 789px) {
  body {
    display: unset;
  }
  .wrapper {
    width: 100%;
  }
  .welcome-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .headerCheckBoxes {
    grid-template-columns: auto auto;
  }
  .sections {
    flex-direction: column;
  }
}

.loader {
  position: absolute;
  background: rgb(255 255 255 / 85%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  display: none;
}
.onload .loader {
  display: flex;
}
.loader .loaderText {
  margin-top: 3.5rem;
  font-size: 90%;
  margin-right: 9px;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 40px;
  height: 40px;
}
.spinner .path {
  stroke: #007eff;
  stroke-linecap: round;
  -webkit-animation: dash 3s ease-in-out infinite;
  animation: dash 3s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.sub-section {
  height: 33.3333%;
}
.sub-section:nth-child(2) {
  height: 20%;
}

/* .sub-section .output-title:first-child
{
  border-top: 1px solid transparent !important;
} */
.sub-section .output-title {
  border-top: 1px solid #ccc;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88%;
  color: #555;
  font-weight: 400;
}

.copy-links {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 5px;
}
.copy-links a {
  text-decoration: none;
  color: black;
}

.disable-banner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100;
  font-weight: 500;
  font-size: 1.25rem;
}

.disable-banner img {
  width: 200px;
  object-fit: contain;
  margin-bottom: 1rem;
}


.storeSelect {
  display: block;
  margin-top: 8px;
  margin-bottom: 0;
}

#monitor__output, #log-content {
  height: 100%;
  overflow: auto;
}

.log {
  display: block;
  /* margin-bottom: 5px; */
  font-family: monospace;
  color: black;
  border-bottom: 1px solid #ccc;
  padding: 0.25rem 1rem;
}
.log * {
  font-size: 85%;
  font-family: monospace;
}
.log code {
  color: rebeccapurple;
}
.log .logTime {
  color: black !important;
}
.log:last-child {
  border-bottom: unset;
}

.log.success {
  background: #c7ffc780;
}
.log.danger {
  background: #e78d8d;
}
.log.warning {
  background: #ffdd9e;
}
.log.default {
  background: rgba(33, 50, 91, 0.077);
}

.titleBtn {
  width: unset;
  margin: 0;
  padding: 0;
  font-weight: 500;
  outline: none;
  border: none;
  background: no-repeat;
  color: #007eff;
}



.logo {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row !important;
  flex-basis: 17%;
  justify-content: flex-start !important;
}


.header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #ccc;
  min-height: 100px;
}

.header-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  /* margin: 0 1rem; */
  padding: 0 1rem;
  border-right: 1px solid #ccc;
}
.header-section:last-child {
  border-right: unset;
}

.center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  /* flex-basis: 10%; */
}


.header-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
}

.header-execute-list
{
  display: flex;
  align-items: center;
  list-style: none;
  gap: 15px;
  padding: 0;
}

.header-execute-list select, button {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.header-execute-list select {
  width: 200px;
}

.executebtn {
  width: 35px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
}
.executebtn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.executebtn img:hover {
  opacity: .8;
}

.executebtn[disabled] {
  opacity: .4;
  cursor: no-drop;
}

.headersLinks
{
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;
  gap: 10px;
}

.headersLinks a {
  text-decoration: none;
  color: black;
}

.performanceTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.performanceTime .col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-right: 1px solid #eee;
  padding: 1rem;
}

.performanceTime .sum {
  background: #c7ffc780;
}

.performanceTime .col span {
  text-transform: uppercase;
  font-size: 12px;
}

.nested-section {
  border-bottom: 1px solid #eee;
}
.i-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color:#555;
}

.inline-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .25rem 1rem;
}
.inline-row .key {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color:#555;
}
.offcanvas-wrapper {
  display: none;
}
.offcanvas 
{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25%;
  background: #FFF;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.offcanvas .offcanvas-header {
  padding: 1rem;
  z-index: 100;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offcanvas .offcanvas-body {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 1rem;
  z-index: 100;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 32%);
  z-index: 10;
}

.offcanvasClose {
  font-weight: 500;
  color: blue;
  cursor: pointer;
}


.log_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 1rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.log_list:last-child {
  border-bottom: unset;
}
.log_list:hover {
  opacity: .75;
}


.b-title
{
  display: flex;
  align-items: center;
  gap: 5px;
}

.liveSymbole {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff0000;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 44, 44, 0.4);
  animation: liveSymbole 1s infinite;
}
.liveSymbole:hover {
  animation: none;
}

@-webkit-keyframes liveSymbole {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes liveSymbole {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}


.b-urls {
  margin: -1rem;
  height: 100%;
  overflow: auto;
}

.b-urls ul {
  padding: 0;
}

.b-urls ul li {
  background: #eee;
  list-style: none;
  font-weight: normal;
  padding: 3px 1rem;
}


.danger-blink {
  background: rgb(238, 50, 50) !important;
  color: #fff;
}

.warning-blink {
  background: rgb(255 221 158) !important;
}


@keyframes blinker {
  0% {opacity: 1;}
  25% {opacity: 0;}
  50% {opacity: 1;}
  75% {opacity: 0;}
  100% {opacity: 1;}
}